.Toastify__toast-container--bottom-center {
    width: 400px;
    margin: 400px / 2;
}

.gelatine-in {
    animation: gelatine 0.3s both;
}

@keyframes gelatine {
    from, to { transform: scale(1, 1); }
    25% { transform: scale(0.95, 1.05); }
    50% { transform: scale(1.05, 0.95); }
    75% { transform: scale(0.975, 1.025); }
}  

.fade-in {
    animation: fadein 0.3s both;
}

@keyframes fadein {
    from { opacity: 0;}
    to {opacity: 1;}
} 